<template>
  <section
    class="text text-center"
    v-editable="blok"
  >
    <v-container>
      <v-row justify="center">
        <v-col cols="12" class="body">
          <h1
            v-if="blok.title"
            v-html="formatTitle(blok.title)"
            :class="blok.underlineTitle === 'none' ? false : `underline-${blok.underlineTitle}`"
          ></h1>
          <div v-if="blok.text" v-html="$md.render(blok.text)"></div>
        </v-col>
      </v-row>

      <v-row class="text-left justify-center">
        <v-col
          v-for="(item, i) in items" :key="i"
          cols="12" sm="6" md="4"
        >
          <news-item :item="item"/>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
	import NewsItem from '@/components/NewsItem'

  export default {
  	name: 'LatestNews',
    props: ['blok'],
    components: {NewsItem},
	  data() {
		  return {
			  items: []
		  }
	  },
	  methods: {
		  formatTitle(string) {
			  const pieces = string.split('\n')
			  return pieces.length === 1 ? string : `<span>${pieces.join('</span><br><span>')}</span>`
		  },
		  async loadItems() {
			  const params = {
				  'filter_query[seo_indexable][in]': 'yes',
				  'filter_query[component][in]': 'page_news',
				  'per_page': 6,
				  'is_published': true,
				  'is_startpage': 0,
				  'sort_by': 'position:ASC'
			  }

			  const storiesResult = await this.$storyapi.get('cdn/stories', params);

			  if (!storiesResult.data.stories) {return;}

			  let stories = {};
			  storiesResult.data.stories.forEach((story) => {
				  stories[story.uuid] = {
					  slug: story.full_slug,
					  date: story.content.date,
					  title: story.name,
					  text: story.content.overviewText,
					  image: story.content.overviewImage
				  };
			  });

			  this.items = stories;
		  },
	  },
	  mounted() {
		  if (!this.itemsLoaded) {
			  this.itemsLoaded = true;
			  this.loadItems()
		  }
	  },
  }
</script>


<style lang="scss" scoped>
  @import "@/assets/scss/_variables.scss";

  section {
    .body {
      > :not(h1) {
        margin-top: $gutter-width;
      }

      h1 {
        display: inline-block;
        color: $text-color;

        &[class*="underline"] {
          &:after {
            content: '';

            display: block;
            max-width: 110px;
            margin: 0 auto;

            padding-top: .5rem;
            border-bottom: 3px solid $text-color;
          }
        }

        &.underline-grey {
          &:after {
            border-color: map-get($grey, 'lighten-2');
          }
        }

        &.underline-blue {
          &:after {
            border-color: map-get($brand-colors, 'boring-blue');
          }
        }

        &.underline-green {
          &:after {
            border-color: map-get($brand-colors, 'green');
          }
        }

        &.underline-orange {
          &:after {
            border-color: map-get($brand-colors, 'orange');
          }
        }

        ::v-deep > span {
          color: $heading-text-color;
        }
      }
    }
  }
</style>
